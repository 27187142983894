<template>
  <div class="CustomerRelations_wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="总客户" name="all"></el-tab-pane>
      <el-tab-pane label="浏览客户" name="view"></el-tab-pane>
      <el-tab-pane label="裂变客户" name="fission"></el-tab-pane>
      <el-tab-pane label="报名客户" name="enrollment"></el-tab-pane>
      <el-tab-pane label="售券客户" name="card"></el-tab-pane>
      <el-tab-pane label="秒杀客户" name="seckill_user"></el-tab-pane>
      <el-tab-pane label="逛店客户" name="go_shop"></el-tab-pane>
      <el-tab-pane label="集赞客户" name="thumb"></el-tab-pane>
      <el-tab-pane label="过程转单客户" name="process_self_order"></el-tab-pane>
      <el-tab-pane label="直播预约客户" name="appointment"></el-tab-pane>
      <el-tab-pane label="进直播间客户" name="live_view"></el-tab-pane>
      <el-tab-pane label="直播下订客户" name="live_order_cus"></el-tab-pane>
      <el-tab-pane label="活动签到客户" name="act_sign"></el-tab-pane>
      <el-tab-pane label="直播转单客户" name="live_self_order_change"></el-tab-pane>
    </el-tabs>
    <div class="select_wrap">
      <div>
        <!-- <span>请输入ID：</span> -->
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入手机号"
          v-model="UserRelationInfo.mobile"
          style="width:180px"
        ></el-input>
      </div>
      <div>
        <!-- <span>请输入名称：</span> -->
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入姓名"
          v-model="UserRelationInfo.name"
          style="width:180px"
        ></el-input>
      </div>
      <div>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入导购员姓名"
          v-model="UserRelationInfo.kpiUserName"
          style="width:180px"
        ></el-input>
      </div>
      <div>
        <el-date-picker
          v-model="UserRelationInfo.dateRange"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          style="width:400px"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入地区搜索（例：XX市-XX区）"
          v-model="UserRelationInfo.registerAddr"
          style="width:280px"
          v-if="activeName!='all'"
        ></el-input>
      </div>
      <div>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入品牌名"
          v-model="UserRelationInfo.brandName"
          style="width:180px"
          v-if="activeName!='all'"
        ></el-input>
      </div>
      <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
      <el-button type="success" class="ml10" icon="el-icon-download" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="mt20" :data="UserRelationList" border stripe style="width: 100%">
      <el-table-column prop="userId" label="客户ID" width="100"></el-table-column>
      <el-table-column prop="nickName" label="客户昵称" width="100"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column prop="registerAddr" label="所在区域"></el-table-column>
      <el-table-column prop="detailAddr" label="详细地址"></el-table-column>
      <el-table-column prop="happenTime" label="首次进入时间" v-if="activeName=='all'||activeName=='live_view'"></el-table-column>
      <el-table-column prop="happenTime" label="进入时间" v-else></el-table-column>
      <el-table-column prop="kpiUserId" label="导购员Id"></el-table-column>
      <el-table-column prop="blName" label="最终归属人" v-if="activeName=='all'"></el-table-column>
      <el-table-column prop="blBrandName" label="归属品牌" v-if="activeName=='all'"></el-table-column>
      <el-table-column prop="blShopName" label="归属门店" v-if="activeName=='all'"></el-table-column>
      <el-table-column prop="blName" label="浏览归属人" v-if="activeName=='view' || activeName === 'appointment'"></el-table-column>
      <el-table-column prop="blBrandName" label="浏览归属品牌" v-if="activeName=='view' || activeName === 'appointment'"></el-table-column>
      <el-table-column prop="blShopName" label="浏览归属门店" v-if="activeName=='view' || activeName === 'appointment'"></el-table-column>
      <el-table-column prop="blName" label="裂变归属人" v-if="activeName=='fission'"></el-table-column>
      <el-table-column prop="blBrandName" label="裂变归属品牌" v-if="activeName=='fission'"></el-table-column>
      <el-table-column prop="blShopName" label="裂变归属门店" v-if="activeName=='fission'"></el-table-column>
      <el-table-column prop="blName" label="报名归属人" v-if="activeName=='enrollment'"></el-table-column>
      <el-table-column prop="blBrandName" label="报名归属品牌" v-if="activeName=='enrollment'"></el-table-column>
      <el-table-column prop="blShopName" label="报名归属门店" v-if="activeName=='enrollment'"></el-table-column>
      <el-table-column prop="blName" label="售券归属人" v-if="activeName=='card'"></el-table-column>
      <el-table-column prop="blBrandName" label="售券归属品牌" v-if="activeName=='card'"></el-table-column>
      <el-table-column prop="blShopName" label="售券归属门店" v-if="activeName=='card'"></el-table-column>
      <el-table-column prop="blName" label="秒杀归属人" v-if="activeName=='seckill_user'"></el-table-column>
      <el-table-column prop="blBrandName" label="秒杀归属品牌" v-if="activeName=='seckill_user'"></el-table-column>
      <el-table-column prop="blShopName" label="秒杀归属门店" v-if="activeName=='seckill_user'"></el-table-column>
      <el-table-column prop="blName" label="逛店归属人" v-if="activeName=='go_shop'"></el-table-column>
      <el-table-column prop="blBrandName" label="逛店归属品牌" v-if="activeName=='go_shop'"></el-table-column>
      <el-table-column prop="blShopName" label="逛店归属门店" v-if="activeName=='go_shop'"></el-table-column>
      <el-table-column prop="blName" label="集赞归属人" v-if="activeName=='thumb'"></el-table-column>
      <el-table-column prop="blBrandName" label="集赞归属品牌" v-if="activeName=='thumb'"></el-table-column>
      <el-table-column prop="blShopName" label="集赞归属门店" v-if="activeName=='thumb'"></el-table-column>
      <el-table-column prop="blName" label="业绩归属人" v-if="activeName=='process_self_order'||activeName=='live_order_cus'||activeName=='live_self_order_change'"></el-table-column>
      <el-table-column prop="blBrandName" label="业绩归属品牌" v-if="activeName=='process_self_order'||activeName=='live_order_cus'||activeName=='live_self_order_change'"></el-table-column>
      <el-table-column prop="blShopName" label="业绩归属门店" v-if="activeName=='process_self_order'||activeName=='live_order_cus'||activeName=='live_self_order_change'"></el-table-column>
      <el-table-column prop="blName" label="邀约归属人" v-if="activeName=='live_view'"></el-table-column>
      <el-table-column prop="blBrandName" label="邀约归属品牌" v-if="activeName=='live_view'"></el-table-column>
      <el-table-column prop="blShopName" label="邀约归属门店" v-if="activeName=='live_view'"></el-table-column>
      <el-table-column prop="blName" label="签到归属人" v-if="activeName=='act_sign'"></el-table-column>
      <el-table-column prop="blBrandName" label="签到归属品牌" v-if="activeName=='act_sign'"></el-table-column>
      <el-table-column prop="blShopName" label="签到归属门店" v-if="activeName=='act_sign'"></el-table-column>
      <el-table-column prop="blBrandCatName" label="所属军团"></el-table-column>
      <el-table-column
        prop="status"
        label="客户状态"
        :render-header="renderHeaderNew"
        v-if="activeName=='view'||activeName=='all' || activeName === 'appointment'"
        :key="Math.random()"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status=='true'" type="primary">有效</el-tag>
          <el-tag v-else type="danger">无效</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalAmount"
        label="奖励金发放"
        v-if="activeName=='view'||activeName=='fission'||activeName=='enrollment'||activeName=='card'||activeName=='thumb'||activeName=='seckill_user'||activeName=='process_self_order'||activeName=='go_shop'||activeName=='live_order_cus'||activeName=='act_sign'||activeName=='live_self_order_change' || activeName === 'appointment'"
        :key="Math.random()"
      ></el-table-column>
      <el-table-column
        prop="totalAmount"
        label="奖励金发放金额"
        v-if="activeName=='live_view'"
        :key="Math.random()"
      ></el-table-column>
      <el-table-column prop="orderNum" label="下订数" v-if="activeName=='process_self_order'||activeName=='live_order_cus'||activeName=='live_self_order_change'" :key="Math.random()"></el-table-column>
      <el-table-column
        prop="processAmount"
        label="过程转单金额"
        :render-header="renderHeaderNew"
        v-if="activeName=='process_self_order'"
        :key="Math.random()"
      ></el-table-column>
      <el-table-column
        prop="processAmount"
        label="直播转单金额"
        :render-header="renderHeaderNew"
        v-if="activeName=='live_self_order_change'"
        :key="Math.random()"
      ></el-table-column>
      <el-table-column
        prop="totalAmount"
        label="备注"
        :render-header="renderHeaderNew"
        v-if="activeName=='view' || activeName === 'appointment'"
        :key="Math.random()"
      >
        <template slot-scope="scope">
          <div>{{scope.row.remark}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="brandProgress"
        label="逛店进度"
        :render-header="renderHeaderNew"
        v-if="activeName=='go_shop'"
        :key="Math.random()"
      >
        <template slot-scope="scope">
          <div>{{scope.row.brandProgress}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="thumbProgress"
        label="集赞进度"
        :render-header="renderHeaderNew"
        v-if="activeName=='thumb'"
        :key="Math.random()"
      >
        <template slot-scope="scope">
          <div>{{scope.row.thumbProgress}}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column fixed="right" label="操作" v-if="false">
        <template slot-scope="scope">
          <el-tag class="m10 cp">查看详情</el-tag>
        </template>
      </el-table-column>-->
    </el-table>
    <div class="tab-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="UserRelationInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="UserRelationTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "CustomerRelations", // 导购员设置-客户关系
  components: {},
  props: {
    activityID: String
  },
  data() {
    return {
      activeName: "all",
      idInput: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ],
      pageIndex: 1, // 当前页码
      pageSize: 10, // 请求单前页码总条数
      activityTotal: 0, // 总条数
      totalDatail: 0,

      // 客户关系用参数
      UserRelationList: [],
      UserRelationTotal: 0,
      UserRelationInfo: {
        actId: 0,
        kpiUserName: "",
        mobile: "",
        page: 1,
        name: "",
        size: 10,
        type: "all"
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.UserRelationInfo = {
        actId: 0,
        kpiUserName: "",
        mobile: "",
        page: 1,
        name: "",
        size: 10,
        type: "all",
        brandName: "",
        registerAddr: "",
      };
      this.UserRelationInfo.type = this.activeName;
      this.getUserRelationList();
    },
    // 表格 页码请求（导购员列表）
    handleCurrentChange(val) {
      this.UserRelationInfo.page = val;
      this.getUserRelationList();
    },
    searchPage() {
      this.UserRelationInfo.page = 1;
      this.getUserRelationList();
    },
    renderHeaderNew(h, { column, $index }) {
      return [
        column.label,
        h(
          "el-tooltip",
          {
            props: {
              content: (function() {
                let label = column.label;
                switch (label) {
                  case "客户状态":
                    return "有效：活动区域内客户；无效：活动区域外或未知区域的客户";
                    break;
                  case "备注":
                    return "未知区域： 用户未授权获取地址位置";
                    break;
                  case "逛店进度":
                    return "左侧为客户已逛门店数量；右侧为客户需逛门店数量";
                    break;
                  case "集赞进度":
                    return "左侧为客户已集数量；右侧为客户需集数量";
                    break;
                  case "过程转单金额":
                    return "门店导购员核销客户优惠券时输入";
                    break;
                  case "直播转单金额":
                    return "门店导购员核销客户直播订单时输入";
                    break;
                }
              })(),
              placement: "top"
            }
          },
          [
            h("span", {
              class: {
                "el-icon-question": true
              }
            })
          ]
        )
      ];
    },
    // 获取客户关系列表
    async getUserRelationList() {
      let params = {
        ...this.UserRelationInfo,
        actId: this.activityID
      }
      if (params.dateRange) {
        params.startTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      delete params.dateRange
      try {
        const data = await GuideAjax.getUserRelationList(params);
        console.log(data);
        if (data.data) {
          this.UserRelationList = data.data.list;
          this.UserRelationTotal = data.data.total;
        } else {
          this.UserRelationList = [];
          this.UserRelationTotal = 0;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出客户关系列表
    async exportData() {
      let params = {
        ...this.UserRelationInfo,
        actId: this.activityID
      }
      if (params.dateRange) {
        params.startTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      delete params.dateRange
      try {
        const data = await GuideAjax.getExportUserRelation(params);
        console.log(data);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "客户关系列表.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    }
  },
  created() {
    this.getUserRelationList();
  },
  mounted() {}
};
</script>

<style lang="scss">
.CustomerRelations_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
