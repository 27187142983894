var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CustomerRelations_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "总客户", name: "all" } }),
          _c("el-tab-pane", { attrs: { label: "浏览客户", name: "view" } }),
          _c("el-tab-pane", { attrs: { label: "裂变客户", name: "fission" } }),
          _c("el-tab-pane", {
            attrs: { label: "报名客户", name: "enrollment" }
          }),
          _c("el-tab-pane", { attrs: { label: "售券客户", name: "card" } }),
          _c("el-tab-pane", {
            attrs: { label: "秒杀客户", name: "seckill_user" }
          }),
          _c("el-tab-pane", { attrs: { label: "逛店客户", name: "go_shop" } }),
          _c("el-tab-pane", { attrs: { label: "集赞客户", name: "thumb" } }),
          _c("el-tab-pane", {
            attrs: { label: "过程转单客户", name: "process_self_order" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "直播预约客户", name: "appointment" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "进直播间客户", name: "live_view" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "直播下订客户", name: "live_order_cus" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "活动签到客户", name: "act_sign" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "直播转单客户", name: "live_self_order_change" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "180px" },
                attrs: { clearable: true, placeholder: "请输入手机号" },
                model: {
                  value: _vm.UserRelationInfo.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.UserRelationInfo, "mobile", $$v)
                  },
                  expression: "UserRelationInfo.mobile"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "180px" },
                attrs: { clearable: true, placeholder: "请输入姓名" },
                model: {
                  value: _vm.UserRelationInfo.name,
                  callback: function($$v) {
                    _vm.$set(_vm.UserRelationInfo, "name", $$v)
                  },
                  expression: "UserRelationInfo.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "180px" },
                attrs: { clearable: true, placeholder: "请输入导购员姓名" },
                model: {
                  value: _vm.UserRelationInfo.kpiUserName,
                  callback: function($$v) {
                    _vm.$set(_vm.UserRelationInfo, "kpiUserName", $$v)
                  },
                  expression: "UserRelationInfo.kpiUserName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-date-picker", {
                staticStyle: { width: "400px" },
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.UserRelationInfo.dateRange,
                  callback: function($$v) {
                    _vm.$set(_vm.UserRelationInfo, "dateRange", $$v)
                  },
                  expression: "UserRelationInfo.dateRange"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.activeName != "all"
                ? _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "280px" },
                    attrs: {
                      clearable: true,
                      placeholder: "请输入地区搜索（例：XX市-XX区）"
                    },
                    model: {
                      value: _vm.UserRelationInfo.registerAddr,
                      callback: function($$v) {
                        _vm.$set(_vm.UserRelationInfo, "registerAddr", $$v)
                      },
                      expression: "UserRelationInfo.registerAddr"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.activeName != "all"
                ? _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "180px" },
                    attrs: { clearable: true, placeholder: "请输入品牌名" },
                    model: {
                      value: _vm.UserRelationInfo.brandName,
                      callback: function($$v) {
                        _vm.$set(_vm.UserRelationInfo, "brandName", $$v)
                      },
                      expression: "UserRelationInfo.brandName"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "success", icon: "el-icon-download" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.UserRelationList, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "userId", label: "客户ID", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "nickName", label: "客户昵称", width: "100" }
          }),
          _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
          _c("el-table-column", { attrs: { prop: "mobile", label: "手机号" } }),
          _c("el-table-column", {
            attrs: { prop: "registerAddr", label: "所在区域" }
          }),
          _c("el-table-column", {
            attrs: { prop: "detailAddr", label: "详细地址" }
          }),
          _vm.activeName == "all" || _vm.activeName == "live_view"
            ? _c("el-table-column", {
                attrs: { prop: "happenTime", label: "首次进入时间" }
              })
            : _c("el-table-column", {
                attrs: { prop: "happenTime", label: "进入时间" }
              }),
          _c("el-table-column", {
            attrs: { prop: "kpiUserId", label: "导购员Id" }
          }),
          _vm.activeName == "all"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "最终归属人" }
              })
            : _vm._e(),
          _vm.activeName == "all"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "all"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "view" || _vm.activeName === "appointment"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "浏览归属人" }
              })
            : _vm._e(),
          _vm.activeName == "view" || _vm.activeName === "appointment"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "浏览归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "view" || _vm.activeName === "appointment"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "浏览归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "fission"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "裂变归属人" }
              })
            : _vm._e(),
          _vm.activeName == "fission"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "裂变归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "fission"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "裂变归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "enrollment"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "报名归属人" }
              })
            : _vm._e(),
          _vm.activeName == "enrollment"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "报名归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "enrollment"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "报名归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "card"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "售券归属人" }
              })
            : _vm._e(),
          _vm.activeName == "card"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "售券归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "card"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "售券归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "seckill_user"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "秒杀归属人" }
              })
            : _vm._e(),
          _vm.activeName == "seckill_user"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "秒杀归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "seckill_user"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "秒杀归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "go_shop"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "逛店归属人" }
              })
            : _vm._e(),
          _vm.activeName == "go_shop"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "逛店归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "go_shop"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "逛店归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "thumb"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "集赞归属人" }
              })
            : _vm._e(),
          _vm.activeName == "thumb"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "集赞归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "thumb"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "集赞归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "process_self_order" ||
          _vm.activeName == "live_order_cus" ||
          _vm.activeName == "live_self_order_change"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "业绩归属人" }
              })
            : _vm._e(),
          _vm.activeName == "process_self_order" ||
          _vm.activeName == "live_order_cus" ||
          _vm.activeName == "live_self_order_change"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "业绩归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "process_self_order" ||
          _vm.activeName == "live_order_cus" ||
          _vm.activeName == "live_self_order_change"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "业绩归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "live_view"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "邀约归属人" }
              })
            : _vm._e(),
          _vm.activeName == "live_view"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "邀约归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "live_view"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "邀约归属门店" }
              })
            : _vm._e(),
          _vm.activeName == "act_sign"
            ? _c("el-table-column", {
                attrs: { prop: "blName", label: "签到归属人" }
              })
            : _vm._e(),
          _vm.activeName == "act_sign"
            ? _c("el-table-column", {
                attrs: { prop: "blBrandName", label: "签到归属品牌" }
              })
            : _vm._e(),
          _vm.activeName == "act_sign"
            ? _c("el-table-column", {
                attrs: { prop: "blShopName", label: "签到归属门店" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "blBrandCatName", label: "所属军团" }
          }),
          _vm.activeName == "view" ||
          _vm.activeName == "all" ||
          _vm.activeName === "appointment"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "status",
                  label: "客户状态",
                  "render-header": _vm.renderHeaderNew
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.status == "true"
                            ? _c("el-tag", { attrs: { type: "primary" } }, [
                                _vm._v("有效")
                              ])
                            : _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("无效")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2431091648
                )
              })
            : _vm._e(),
          _vm.activeName == "view" ||
          _vm.activeName == "fission" ||
          _vm.activeName == "enrollment" ||
          _vm.activeName == "card" ||
          _vm.activeName == "thumb" ||
          _vm.activeName == "seckill_user" ||
          _vm.activeName == "process_self_order" ||
          _vm.activeName == "go_shop" ||
          _vm.activeName == "live_order_cus" ||
          _vm.activeName == "act_sign" ||
          _vm.activeName == "live_self_order_change" ||
          _vm.activeName === "appointment"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: { prop: "totalAmount", label: "奖励金发放" }
              })
            : _vm._e(),
          _vm.activeName == "live_view"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: { prop: "totalAmount", label: "奖励金发放金额" }
              })
            : _vm._e(),
          _vm.activeName == "process_self_order" ||
          _vm.activeName == "live_order_cus" ||
          _vm.activeName == "live_self_order_change"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: { prop: "orderNum", label: "下订数" }
              })
            : _vm._e(),
          _vm.activeName == "process_self_order"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "processAmount",
                  label: "过程转单金额",
                  "render-header": _vm.renderHeaderNew
                }
              })
            : _vm._e(),
          _vm.activeName == "live_self_order_change"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "processAmount",
                  label: "直播转单金额",
                  "render-header": _vm.renderHeaderNew
                }
              })
            : _vm._e(),
          _vm.activeName == "view" || _vm.activeName === "appointment"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "totalAmount",
                  label: "备注",
                  "render-header": _vm.renderHeaderNew
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("div", [_vm._v(_vm._s(scope.row.remark))])]
                      }
                    }
                  ],
                  null,
                  false,
                  782348147
                )
              })
            : _vm._e(),
          _vm.activeName == "go_shop"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "brandProgress",
                  label: "逛店进度",
                  "render-header": _vm.renderHeaderNew
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.brandProgress))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1717003543
                )
              })
            : _vm._e(),
          _vm.activeName == "thumb"
            ? _c("el-table-column", {
                key: Math.random(),
                attrs: {
                  prop: "thumbProgress",
                  label: "集赞进度",
                  "render-header": _vm.renderHeaderNew
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.thumbProgress))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2243643818
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.UserRelationInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.UserRelationTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }